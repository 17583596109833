@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

*{
    margin:0;
    padding:0;
    font-size: 16px;
}

html{
    background-color: rgb(73, 73, 73);
    font-family: 'Ubuntu', sans-serif;
    body{
        background-color: rgb(253, 244, 234);
        // background:linear-gradient(135deg, grey, lightgrey, grey,lightgrey,grey);
        margin:0 auto;
        max-width:1200px;
        
        .mainWrapper{
            display: grid;
        grid-template-columns: 25fr 55fr;
        grid-template-areas:
        "upright upright"
        "aside main";
        
        header{
            padding: 15px;
            grid-area: upright;
            background-color:darkred;
            color: antiquewhite;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            box-shadow: 0 5px 5px rgb(221, 198, 170);
            position:sticky;
            top:0;
            select{
                font-size: 2.5rem;
                background-color: darkred;
                option{
                    font-size: 2.5rem;
                    background-color: darkred;
                }
            }
            }
            aside{
                padding: 0 15px;
                grid-area: aside;
                color:black;
                article{
                    table{
                        td,th{
                            padding:1px;
                        }
                        // width: 100%;
                        // background-color: red;
                    }
                }
            }
            main{
                // width: 90%;
                margin-right: 15px;
                padding: 10px 15px;
                border-radius: 15px;
                // background-color: white;
                box-shadow: -2px -2px 5px rgb(221, 198, 170) ;
                table{
                    border-collapse: collapse;
                    width: 100%;
                }
                article{
                    section{
                        &:hover{
                            box-shadow: 0 0 5px rgb(221, 198, 170);
                            background-color: antiquewhite;
                        }
                        div{
                            display: flex;
                            justify-content: space-between;
                        }
                    }
                }
            }     
        }
    }
}


aside{

}
main{

}

h1{
    font-size: 2.5rem;
    font-variant: small-caps;
}
h2{
    font-size: 2rem;
    font-variant: small-caps;
}
h3{
    font-size: 1.8rem;;
    color:darkred;
    margin-top: 30px;
    margin-bottom: 10px;
    font-variant: small-caps;
}
h4{
    font-size: 1.3rem;
    margin-top: 20px;
}
h5{
    margin-top:15px;
    font-style: italic;
    font-weight: normal;
}    

p{
    line-height: 130%;
}

ul{
    padding-left: 20px;
    list-style-type: circle;
}
li{
    margin-top:10px;
}
img{
    padding-top: 0;
    width: 100%;
    border-radius: 10px;
}

a{
    color:darkred;
}

#navBtn{
    font-size: 2rem;
    color: darkred;
}

svg{
    margin-right: 10px;
}
// h1{
//     padding:0;
//     margin:0;
// }
// body{
//     font-family: 'Ubuntu', sans-serif;
//     margin:0;
//     padding:0;
//     div{
//         padding:0 2%;
//         header{
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             height: 10vh;
//             padding:0 5px;
//             select{
//                 height: 85%;
//                 font-size: 2.5rem;
//                 background-color: black;
//                 color: white;
//                 border-radius: 10%;
//             }
//         }
//         main{
//             border-radius: 10px;
//             height: 85vh;
//             padding:0 5px;
//         }
//         footer{
//             height: 5vh;
//             padding:0 5px;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//         }
//     }
// }


// #ke{
//     background-color: black; 
//     header{
//         background-color: black;
//         color:white;
//         a{
//             color:white;
//         }
//         a:hover{
//             color:rgb(181,0,0);
//         }
//     }
//     main{
//         background-color: rgb(181,0,0);
//         color:white;
//         box-shadow:0 0 10px white inset;
//     }
//     footer{
//         background-color: rgb(0,99,2);
//         color:white;
//         box-shadow:0 5px 10px black inset;
//     }
// }
// #de{
//     background-color: black;
//     header{
//         background-color: black;
//         color:rgb(254,205,1);
//         a{
//             color:rgb(254,205,1);
//         }
//         a:hover{
//             color:white;
//         }
//     }
//     main{
//         background-color: rgb(214,0,0);
//         color:rgb(254,205,1);
//         box-shadow:0 0 10px rgb(254,205,1) inset;
//     }
//     footer{
//         background-color: rgb(254,205,1);
//         color:black;
//         box-shadow:0 5px 10px black inset;
//     }
// }
// #en{
//     background-color: rgb(49,49,136);
//     header{
//         background-color: rgb(49,49,136);
//         color:white;
//         a{
//             color:white;
//         }
//         a:hover{
//             color:rgb(236,27,36);
//         }
//         select{
//             background-color: rgb(49,49,136);
//         }
//     }
//     main{
//         background-color: rgb(236,27,36);;
//         color:white;
//         box-shadow:0 0 10px white inset;
//     }
//     footer{
//         background-color: rgb(49,49,136);
//         color:white;
//     }
// }

// 461
@media screen and (max-width:550px) {
    html{
        body{
            .mainWrapper{
                main{
                    table{
                        td{
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:461px) {
    html{
        body{
            .mainWrapper{
                grid-template-columns: 1fr;
                grid-template-areas:
                    "upright"
                    "aside"
                    "main";
                main{
                    margin-right: 0;
                    margin-top: 15px;
                }
            }
        }
    }
}